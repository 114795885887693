import { grecaptcha } from '~src/globals/google.ts'
import { appData } from '~src/globals/variables.ts'
import { auth } from '~public/js/src/default/two-factor-authentication.js'
import { $, jQuery } from '~src/globals/jquery.ts'
import { getCookie, setCookie } from '~src/utils.ts'

jQuery(document).ready(function () {
  if ($('body#home').length > 0) {
    // bookgoodlook main landingpage with search
    searchDates.getDatesForSearch()
    salon.getSalonNames()
    salon.getSalonsSearchSalons()
    $('.se-picker').selectpicker()
  } else if ($('body#dates').length > 0) {
    // /{city} endpoint
    searchDates.getDatesForSearch()
    $('.se-picker').selectpicker()
  } else if ($('body#salon').length > 0) {
    // e.g. /wieselburg/friseur/musterfirma-1 - salon detail page
    salon.getDates()
  } else if ($('body#salons').length > 0) {
    // /salons endpoint
    salons.setIsotope()
  }

  /**
   * Common
   **/

  //Navbar mobile
  //Show menu
  $('.nav-content').show()
  //Toggle navbar
  $('.navbar-toggle').click(function () {
    $(this).toggleClass('open')
    $('.nav-content').toggleClass('open')
  })
  var iScrollPos = 0
  var menu = $('.navbar-toggle')
  $(window).scroll(function () {
    var iCurScrollPos = $(this).scrollTop()
    if (iCurScrollPos > iScrollPos) {
      if (iCurScrollPos > 50) {
        //down
        if (!menu.hasClass('unpinned')) {
          menu.removeClass('pinned')
          menu.addClass('unpinned')
        }
      }
    } else {
      //up
      if (!menu.hasClass('pinned')) {
        menu.removeClass('unpinned')
        menu.addClass('pinned')
      }
    }
    iScrollPos = iCurScrollPos
  })

  //Animations
  $().waypoint &&
    $('body').imagesLoaded(function () {
      $('.animate_afc, .animate_afl, .animate_afr, .animate_afb, .animate_fade').waypoint(
        function () {
          if (!$(this.element).hasClass('animate_start')) {
            var e = $(this.element)
            setTimeout(function () {
              e.addClass('animate_start')
            }, 20)
          }
        },
        {
          offset: '85%',
          triggerOnce: !0,
        },
      )
    })

  //Scroll top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').addClass('visible')
    } else {
      $('.scrollup').removeClass('visible')
    }
  })
  jQuery('.scrollup, .btn-go-top').click(function () {
    jQuery('html, body').animate(
      {
        scrollTop: 0,
      },
      600,
    )
    return false
  })

  //Carousel
  $('.testimonials-carousel').carousel({ interval: 5000, pause: 'hover' })

  /**
   * Home
   **/

  //Slider
  $('#cycle-container').cycle({ speed: 1500, delay: 5000, slides: '> .cycle-image', log: false })

  //Home search form
  $('.form-search-dates .btn-search-home').on('click', function (event) {
    var city = $('#se-city').val().replace(/\s+/g, '-')
    var product = $('#se-product').val()
    var date = $('.datepicker-box').val()
    location.href = encodeURI(
      '/' + city.toLowerCase() + '?reservation[product]=' + product + '&reservation[date]=' + date,
    )
    event.preventDefault()
  })

  //Salon search
  //Show search box
  $('.salon-search-box').show()
  //Toggle open class
  $('.btn-search-salon').on('click', function () {
    $('.salon-search-box').addClass('open')
    setTimeout(function () {
      $('#form-search-salon input').focus()
    }, 1000)
  })
  $('.salon-search-box button.close').on('click', function () {
    $('.salon-search-box').removeClass('open')
  })
  $(document).keydown(function (e) {
    if (e.keyCode === 27 && $('.salon-search-box').hasClass('open')) {
      $('.salon-search-box').removeClass('open')
    }
  })
  $('#form-search-salon').submit(function (event) {
    event.preventDefault()
    salon.getLink($(this).serialize())
  })

  /**
   * Home & Dates
   **/
  //City changed
  $('#se-city').on('change', function () {
    setCookie('search-city', $(this).val(), 30 * 12 * 30, true)
    searchDates.getDatesForSearch()
  })

  //Product changed
  $('#se-product').on('change', function () {
    $('.input-box.date input').datepicker('destroy')
    searchDates.datepicker = null
    searchDates.setSearchDates($(this).val(), searchDates.dates)
    searchDates.checkSelectedDate($(this).val())
    $('.datepicker-box').focus()
  })

  /**
   * Salon
   **/
  //Tabs google maps
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    if ($(e.target).attr('href').substr(1) == 'map') {
      $('#map').show(0, function () {
        if (!$('#map .loader iframe').length) {
          $('#map .loader').html(appData.custom.salonData?.map ?? '')
        }
      })
    }
  })

  /**
   * FAQS
   **/

  //Collapse (FAQs)
  jQuery('.accordionMod').each(function (index) {
    var thisBox = jQuery(this).children(),
      thisMainIndex = index + 1

    jQuery(this).attr('id', 'accordion' + thisMainIndex)

    thisBox.each(function (i) {
      var thisIndex = i + 1,
        thisParentIndex = thisMainIndex,
        thisMain = jQuery(this).parent().attr('id'),
        thisTriggers = jQuery(this).find('.accordion-toggle'),
        thisBoxes = jQuery(this).find('.accordion-inner')

      jQuery(this).addClass('panel')
      thisBoxes.wrap(
        '<div id="collapseBox' + thisParentIndex + '_' + thisIndex + '" class="panel-collapse collapse" />',
      )
      thisTriggers.wrap('<div class="panel-heading" />')
      thisTriggers
        .attr('data-toggle', 'collapse')
        .attr('data-parent', '#' + thisMain)
        .attr('data-target', '#collapseBox' + thisParentIndex + '_' + thisIndex)
    })
    jQuery('#accordion' + thisMainIndex + ' .accordion-toggle').prepend('<i class="fa-regular fa-plus" /> ')
    jQuery('.accordionMod .accordion-toggle').click(function () {
      jQuery(this).parent().parent().siblings().find('.accordion-toggle').removeClass('current')
      jQuery(this).parent().parent().siblings().find('.accordion-toggle > .fa-regular').removeClass('fa-minus')
      jQuery(this).parent().parent().siblings().find('.accordion-toggle > .fa-regular').addClass('fa-plus')

      if (jQuery(this).parent().parent().find('.panel-collapse').is('.in')) {
        jQuery(this).removeClass('current')
        jQuery(this).find('.fa-regular').removeClass('fa-minus')
        jQuery(this).find('.fa-regular').addClass('fa-plus')
      } else {
        jQuery(this).addClass('current')
        jQuery(this).find('.fa-regular').addClass('fa-minus')
        jQuery(this).find('.fa-regular').removeClass('fa-plus')
      }
    })
  })

  $('#login-form').submit(function (event) {
    event.preventDefault()
    auth.login($(this), '/intern/dashboard')
  })

  /**
   * Contact
   **/
  //Contact forms
  $('#contact-form, #password-form, #salon-inquiry-form, #register-form, #set-password-form').submit(function (event) {
    event.preventDefault()
    var url = $(this).attr('action')
    var id = this.id

    void $.ajax({
      type: 'POST',
      url: url,
      data: $(this).serialize(),
      beforeSend: function () {
        $('#' + id)
          .find('.btn-load')
          .button('loading')
      },
      complete: function () {
        $('#' + id)
          .find('.btn-load')
          .button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (json.error) {
          var e = ''
          for (var i = 0; i < json.error.length; i++) {
            e = e + json.error[i] + '<br />'
          }
          $('#' + id)
            .find('.alert')
            .remove()
          $('#' + id).prepend(alerts.getAlertDanger(e))
        } else {
          $('#' + id)
            .find('.alert')
            .remove()
          if (id === 'password-form') {
            $('#' + id)
              .parent()
              .prepend(alerts.getAlertSuccess(json.msg))
            $('#' + id).hide()
          } else if (id === 'set-password-form') {
            $('#' + id).hide()
            $('.finished-text').show()
          } else {
            $('#' + id).prepend(alerts.getAlertSuccess(json.msg))
          }
        }
      },
    })
  })

  /**
   * Newsletter
   **/
  //Newsletter forms
  $('#newsletter-form, #newsletter-modal-form').submit(function (event) {
    event.preventDefault()
    var url = $(this).attr('action')
    var id = this.id

    void $.ajax({
      type: 'POST',
      url: url,
      data: $(this).serialize(),
      beforeSend: function () {
        $('#' + id)
          .find('.btn-load')
          .button('loading')
      },
      complete: function () {
        $('#' + id)
          .find('.btn-load')
          .button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (json.error) {
          var e = ''
          for (var i = 0; i < json.error.length; i++) {
            e = e + json.error[i] + '<br />'
          }
          $('#' + id)
            .find('.alert')
            .remove()
          $('#' + id).prepend(alerts.getAlertDanger(e))
        } else {
          location.href = '/newsletter/success'
        }
      },
    })
  })

  /**
   * Checkout
   **/
  //Toggle phone
  $('#cb-phone').on('click', function () {
    $('.phone-group').toggle(400)
  })
  //Order form
  $('#order-form').submit(function (event) {
    event.preventDefault()

    if (grecaptcha && grecaptcha.enterprise.getResponse() === '') {
      $('.g-recaptcha').siblings('.error-label').show()
      return
    } else {
      $('.g-recaptcha').siblings('.error-label').hide()
    }

    void $.ajax({
      type: 'POST',
      url: '/api' + window.location.pathname,
      data: $(this).serialize(),
      beforeSend: function () {
        $('.btn-load').button('loading')
      },
      complete: function () {
        $('.btn-load').button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (json.error) {
          var e = ''
          for (var i = 0; i < json.error.length; i++) {
            e = e + json.error[i] + '<br />'
          }
          $('#order-form .alert').remove()
          $('#order-form .btn-checkout').before(alerts.getAlertDanger(json.msg))
        } else {
          location.href = '/success'
        }
      },
    })
  })

  /**
   * Salons
   **/
  //Hairdresser all, city changed
  $('.btn-city').on('click', function (event) {
    event.preventDefault()
    var btn = $(this)
    var city = $(this).data('city')
    var city_txt = $(this).text()

    if (city !== getCookie('search-city-hd-all')) {
      setCookie('search-city-hd-all', city, 30 * 12 * 50, true)
      void $.ajax({
        type: 'POST',
        url: '/api/salons',
        data: { city: $(this).data('city') },
        beforeSend: function () {
          btn.button('loading')
        },
        complete: function () {
          btn.button('reset')
        },
        success: function (data) {
          var json = $.parseJsonString(data)

          $('#filters').html(json.salon_sort)
          $('.portfolio').html(json.hd)

          if (salons.jQuerycontainer != null) {
            $('.portfolio').isotope('destroy')
          }
          salons.setIsotope()

          $('#salon-all-select-city').modal('hide')

          $('.span-city-modal').html(city_txt)
          $('#salon-all-select-city .btn').removeClass('btn-special, btn-color')
          $('#salon-all-select-city .btn').addClass('btn-default')
          btn.removeClass('btn-default')
          btn.addClass('btn-special, btn-color')
        },
      })
    } else $('#salon-all-select-city').modal('hide')
  })

  /**
   * Search dates
   **/
  //Dates search form
  $('#dates-form').submit(function (event) {
    searchDates.handleDatesForm()
    event.preventDefault()
  })

  //Dates sort
  $('.dates-sort').on('click', function (event) {
    searchDates.handleDatesSort($(this))
    event.preventDefault()
  })

  /**
   * Ratings
   **/
  //Rating, poll site
  $('.poll-star').on({
    mouseover: function () {
      $(this).prevAll().addBack().removeClass('fa-regular fa-star')
      $(this).prevAll().addBack().addClass('fa-solid fa-star')
    },
    mouseleave: function () {
      $(this).prevAll().addBack().removeClass('fa-solid fa-star')
      $(this).prevAll().addBack().addClass('fa-regular fa-star')
    },
    click: function () {
      $(this).parents('.rating-icons-star').find('.poll-star').off('mouseleave')
      $(this).parents('.rating-icons-star').find('.poll-star').off('mouseover')
      $(this)
        .parents('.rating-icons-star')
        .find('.poll-star')
        .removeClass('fa-solid fa-star')
        .addClass('fa-regular fa-star')
      $(this).prevAll().addBack().removeClass('fa-regular fa-star')
      $(this).prevAll().addBack().addClass('fa-solid fa-star')

      var l = $(this).prevAll().length + 1
      if ($(this).parent().hasClass('for-subcategory')) {
        if (l < 3) $('.subcategory').show('slow')
        else $('.subcategory').hide('slow')
      }
    },
  })
  $('.btn-feedback').on('click', function () {
    rating.handleRating()
  })

  /**
   * Storno
   **/
  //Storno btn clicked
  $('#storno .btn-storno').on('click', function (event) {
    event.preventDefault()

    void $.ajax({
      type: 'POST',
      url: '/api/storno',
      data: { oid: $(this).data('oid'), token: $(this).data('token'), order: $(this).data('order') },
      beforeSend: function () {
        $('.btn-load').button('loading')
      },
      complete: function () {
        $('.btn-load').button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (!json.error) {
          $('.btn-storno').hide()
          $(alerts.getAlertSuccess(json.msg)).insertAfter('.media-body')
        }
      },
    })
  })

  /**
   * Salon
   **/
  //Product category clicked
  $('.dates-box-product').on('click', '.menu-group .group-title', function () {
    $(this).next('.offers').toggle(400)
  })

  // in salon detail page, on step 1, choosing the service
  //Product click
  $('.dates-box-product').on('click', 'a', function () {
    var text = $(this).text()
    $('.dates-product .summery-text').html(text)
    $('.dates-box-product').addClass('hide')
    $('.dates-product .summery').removeClass('hide')

    salon.product = $(this).attr('data-value')
    salon.time = $(this).attr('data-time')
    salon.setCalendar(salon.responseGetDates.dates, salon.product, salon.time)
    $('.dates-box-calendar').removeClass('hide')
  })

  // in salon detail page, on step 3, choosing the time
  //Time clicked
  $(document).on('click', '.dates-time-btn', function () {
    var text = $(this).text()
    var discount = $(this).attr('data-discount')
    var did = $(this).attr('data-did')

    $('.dates-time .summery-text').html(text)
    $('.dates-box-time').addClass('hide')
    $('.dates-time .summery').removeClass('hide')

    salon.setPrice(
      salon.responseGetDates.prices,
      salon.responseGetDates.product,
      discount,
      salon.responseGetDates.currency,
    )
    $('.date-price').removeClass('hide')

    $('.dates-button').attr('href', '/checkout/' + did + '/' + salon.product)
    $('.dates-button').removeClass('hide')
  })

  //Edit product
  $('.dates-product .edit').on('click', function () {
    $('.dates-product .summery-text').html()
    $('.dates-product .summery').addClass('hide')
    $('.dates-box-product').removeClass('hide')

    $('.dates-calendar').datepicker('remove')
    $('.dates-date .summery').addClass('hide')
    $('.dates-box-calendar').addClass('hide')

    $('.dates-box-time').addClass('hide')
    $('.dates-time .summery').addClass('hide')

    $('.date-price').addClass('hide')
    $('.dates-button').addClass('hide')
  })

  //Edit dates
  $('.dates-date .edit').on('click', function () {
    $('.dates-box-calendar').removeClass('hide')
    $('.dates-date .summery').addClass('hide')
    $('.dates-box-time').addClass('hide')
    $('.dates-time .summery').addClass('hide')

    $('.date-price').addClass('hide')
    $('.dates-button').addClass('hide')
  })

  //Edit times
  $('.dates-time .edit').on('click', function () {
    $('.dates-box-time').removeClass('hide')
    $('.dates-time .summery').addClass('hide')

    $('.date-price').addClass('hide')
    $('.dates-button').addClass('hide')
  })

  //Rating ajax
  $(document).on('click', '.pagination a', function (event) {
    event.preventDefault()
    var index = $(this).attr('rel')
    var said = $('.pagination').attr('data-said')
    var rating_value = $('.pagination').attr('data-rating-value')
    if (rating_value === undefined) rating_value = 0

    if (index !== undefined) {
      rating.getRatings(index, said, rating_value)
    }
  })

  $(document).on('click', '.rating-overview-links a', function (event) {
    event.preventDefault()
    var rating_value = $(this).data('rating-value')
    var said = $('.rating-overview-links').data('said')

    rating.getRatings(0, said, rating_value)
  })

  //Scroll to ratings
  $('.rating-title').on('click', function (event) {
    event.preventDefault()
    // booking storno page stars are clickable, but do not have rating container
    var offset = $('.rating-box-salon').offset() || { top: 0 }
    jQuery('html, body').animate(
      {
        scrollTop: offset.top,
      },
      600,
    )
  })
})

/**
 * Ratings
 **/
var rating = {
  handleRating: function () {
    var p1 = $('#poll1 .fa-solid.fa-star').length
    var p2 = $('#poll2 .fa-solid.fa-star').length
    var p3 = $('#poll3').val()
    var p4 = $('#poll4').val()
    var p5 = $('#poll5').val()
    var p6 = $('#poll6 .fa-solid.fa-star').length
    var p7 = null

    if (p6 < 3 && p6 > 0) {
      p7 = $('#poll7').val()
    }

    void $.ajax({
      type: 'POST',
      url: '/api/rating',
      data: {
        oid: $('#rating-box').data('oid'),
        token: $('#rating-box').data('token'),
        type: $('#rating-box').data('type'),
        p1: p1,
        p2: p2,
        p3: p3,
        p4: p4,
        p5: p5,
        p6: p6,
        p7: p7,
      },
      beforeSend: function () {
        $('.btn-load').button('loading')
      },
      complete: function () {
        $('.btn-load').button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (json.error) {
          var e = ''
          for (var i = 0; i < json.error.length; i++) {
            e = e + json.error[i] + '<br />'
          }
          $('.poll .alert').remove()
          $('.btn-feedback').before(alerts.getAlertDanger(e))
        } else {
          location.href = '/rating/success'
        }
      },
    })
  },

  getRatings: function (index, said, value) {
    void $.ajax({
      type: 'POST',
      url: '/api/salon/ratings',
      data: 'index=' + index + '&said=' + said + '&rating_value=' + value,
      beforeSend: function () {
        $('.ratings-all').addClass('load')
        $('.loader.ratings').removeClass('hide')
      },
      complete: function () {
        $('.ratings-all').removeClass('load')
        $('.loader.ratings').addClass('hide')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (json) {
          // The "|| json" is just to be down compatible to old controller
          // you can safely delete it if this blame is older than one week
          $('.ratings-all').html(json.html || json)
          jQuery('html, body').animate(
            {
              scrollTop: $('.rating-box-salon').offset().top,
            },
            600,
          )
        }
      },
    })
  },
}

/**
 * Search dates
 **/
;(function (window) {
  History.Adapter.bind(window, 'statechange', function () {
    var State = History.getState()
    if (State.data.state == 'dates') {
      searchDates.getDatesByHairdressers(State.data.city_ajax, State.data.product, State.data.date, State.data.sort)
      $('#se-city').val(State.data.city)
      $('#se-product').val(State.data.product)
      $('.datepicker-box').val(State.data.date)
      $('.input-box.date input').datepicker('setDate', State.data.date)
      $('.filter-sort').find('a.selected').removeClass('selected')
      $('.dates-sort[rel="' + State.data.sort + '"]').addClass('selected')
    }
  })
})(window)

var searchDates = {
  dates: null,
  datepicker: null,

  // used in /{city} endpoint
  getDatesForSearch: function () {
    if ($('.form-search-dates').length) {
      void $.ajax({
        type: 'POST',
        url: '/api/dates/search',
        data: { city: $('#se-city').val() },
        beforeSend: function () {
          $('.btn-search').button('loading')
        },
        complete: function () {
          $('.btn-search').button('reset')
        },
        success: function (data) {
          const json = $.parseJsonString(data)

          searchDates.dates = json.dates
          const product = $('#se-product').val()
          searchDates.setSearchDates(product, searchDates.dates)
          if ($('.datepicker-box').val() === '' && searchDates.dates[product] && searchDates.dates[product][0] !== '') {
            $('.input-box.date input').datepicker('setDate', searchDates.dates[product][0])
          }
        },
      })
    }
  },

  setSearchDates: function (product, dates) {
    if (searchDates.datepicker !== null) {
      $('.input-box.date input').datepicker('destroy')
      $('.datepicker-box').val('')
    }

    //Datepicker
    searchDates.datepicker = $('.input-box.date input').datepicker({
      language: 'std',
      format: 'dd.mm.yyyy',
      autoclose: true,
      beforeShowDay: function (date) {
        if (
          (dates[product] && $.inArray(formatLocalDate(date), dates[product]) >= 0) ||
          formatLocalDate(date) === dates[product]
        )
          return true
        else return false
      },
    })
  },

  checkSelectedDate: function (product) {
    const oldDate = formatLocalDate($('.input-box.date input').datepicker('getDate'))
    if ($.inArray(oldDate, searchDates.dates[product]) == -1)
      $('.input-box.date input').datepicker('setDate', searchDates.dates[product][0])
  },

  handleDatesForm: function () {
    var city = $('#se-city').val()
    var city_ajax = $('#se-city').find(':selected').data('value')
    var product = $('#se-product').val()
    var date = $('.datepicker-box').val()
    var sort = $('.filter-sort').find('a.selected').attr('rel')
    History.pushState(
      { state: 'dates', city: city, city_ajax: city_ajax, product: product, date: date, sort: sort },
      document.title,
      '/' +
        city.toLowerCase() +
        '?reservation[product]=' +
        product +
        '&reservation[date]=' +
        date +
        '&reservation[sort]=' +
        sort,
    )
  },

  handleDatesSort: function (obj) {
    var city = $('#se-city').val()
    var city_ajax = $('#se-city').find(':selected').data('value')
    var product = $('#se-product').val()
    var date = $('.datepicker-box').val()
    var sort = obj.attr('rel')
    History.pushState(
      { state: 'dates', city: city, city_ajax: city_ajax, product: product, date: date, sort: sort },
      document.title,
      '/' +
        city.toLowerCase() +
        '?reservation[product]=' +
        product +
        '&reservation[date]=' +
        date +
        '&reservation[sort]=' +
        sort,
    )
    $('.filter-sort').find('a.selected').removeClass('selected')
    obj.addClass('selected')
  },

  getDatesByHairdressers: function (city, product, date, sort) {
    void $.ajax({
      type: 'POST',
      url: '/api/dates',
      data: { city: city, product: product, date: date, sort: sort },
      beforeSend: function () {
        $('#dates-form').find('.btn-load').button('loading')
        $('.content-search-salons #dates-container').addClass('load')
        $('.loader.dates').removeClass('hide')
      },
      complete: function () {
        $('#dates-form').find('.btn-load').button('reset')
        $('.content-search-salons #dates-container').removeClass('load')
        $('.loader.dates').addClass('hide')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        $('#dates-container').html(json.dates.html)
        $('#dates-count').html(json.dates.count)
        $('#dates-future').attr('rel', json.datesFuture.date)
        $('#dates-future').html(json.datesFuture.msg)

        $('html,body').animate({ scrollTop: $('#dates-form').offset().top - 10 }, 'slow')
      },
    })
  },
}

/**
 * Salon
 **/
var salon = {
  time: null,
  product: null,

  responseGetDates: null,

  //Get all dates by hairdresser
  getDates: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/salon/' + $('.booking-box').data('salon-id') + '/getdates',
      data: { did: getURLParameter('checkout[did]'), product: getURLParameter('checkout[product]') },
      complete: function () {
        $('.loader.dates').addClass('hide')
      },
      success: function (data) {
        const json = $.parseJsonString(data)

        salon.responseGetDates = json

        salon.product = json.request.product

        if (!json.error) {
          if (json.dates.length > 0) {
            salon.setProducts(json.products)
            $('.booking-box').removeClass('hide')

            //Show widget link
            $('.online-bookings').removeClass('hidden')

            if (json.date.length > 0) {
              $('.dates-product .summery-text').html(
                $('.dates-box-product a[data-value="' + json.request.product + '"]').text(),
              )
              $('.dates-product .summery').removeClass('hide')
              $('.dates-date .summery-text').html(json.date[0].date)
              $('.dates-date .summery').removeClass('hide')
              $('.dates-time .summery-text').html(json.date[0].time + ' -' + json.date[0].discount + '%')
              $('.dates-time .summery').removeClass('hide')
              $('.date-price').removeClass('hide')

              //Set calender
              salon.setCalendar(json.dates, json.request.product, json.request.time)

              //Set times
              salon.setTimes(json.dates, json.date[0].date, json.request.time)

              //Set prices
              salon.setPrice(json.prices, json.request.product, json.date[0].discount, json.currency)

              //Set checkout button
              $('.dates-button').attr('href', '/checkout/' + json.date[0].ID + '/' + json.request.product)
              $('.dates-button').removeClass('hide')

              //Open the right products group
              $('.menu-group .offers').hide()
              $('.menu-group[data-group="' + json.request.group + '"] .offers').show()
            } else $('.dates-box-product').removeClass('hide')
          } else $('.alert-dates').removeClass('hide')
        }
      },
    })
  },

  //Set all possible products
  setProducts: function (products) {
    $('.dates-box-product .products').html(products)
  },

  //Set calendar dates
  setCalendar: function (dates, product, time) {
    var tmp_dates = [],
      length = dates.length

    for (var i = 0; i < length; i++) {
      if (parseInt(dates[i].time) >= parseInt(time) && $.inArray(product, dates[i].product) != -1) {
        tmp_dates.push(dates[i].date_date)
      }
    }

    $('.dates-calendar').datepicker({
      language: 'std',
      format: 'dd.mm.yyyy',
      beforeShowDay: function (date) {
        if ($.inArray(formatLocalDate(date), tmp_dates) >= 0) return true
        else return false
      },
    })

    // available in salon detail page
    $('.dates-calendar')
      .datepicker()
      .on('changeDate', function () {
        let date

        if ($('.dates-calendar').datepicker('getDate') != 'Invalid Date') {
          date = new Date($('.dates-calendar').datepicker('getDate'))
          $('.dates-date .summery-text').html(formatLocalDate(date))
        }
        $('.dates-date .summery').removeClass('hide')
        $('.dates-box-calendar').addClass('hide')

        //Set times
        time = $('.dates-box-product a[data-value="' + salon.product + '"]').attr('data-time')
        salon.setTimes(salon.responseGetDates.dates, formatLocalDate(date), salon.time)

        $('.dates-box-time').removeClass('hide')
      })
    $('.day').removeClass('active')
  },

  //Set times
  setTimes: function (dates, date, time) {
    var tmp_dates = [],
      employees_ID = [],
      employees_name = [],
      employees_rank = [],
      length = dates.length

    for (var i = 0; i < length; i++) {
      if (
        parseInt(dates[i].time) >= parseInt(time) &&
        date == dates[i].date_date &&
        $.inArray(salon.product, dates[i].product) != -1
      ) {
        tmp_dates.push(dates[i])
        if ($.inArray(dates[i].EID, employees_ID) == -1) {
          employees_ID.push(dates[i].EID)
          employees_name.push(dates[i].e_name)
          employees_rank.push(dates[i].e_rank)
        }
      }
    }

    var html = ''
    for (var a = 0; a < employees_ID.length; a++) {
      html =
        html +
        '<tr><td class="stylist">' +
        employees_name[a] +
        ' <span class="stylist-rank">' +
        employees_rank[a] +
        '</span></td></tr><tr><td>'
      for (var b = 0; b < tmp_dates.length; b++) {
        if (tmp_dates[b].EID == employees_ID[a])
          html =
            html +
            '<span><a class="dates-time-btn" data-did="' +
            tmp_dates[b].ID +
            '" data-discount="' +
            tmp_dates[b].discount +
            '">' +
            tmp_dates[b].date_time +
            ' <strong>-' +
            tmp_dates[b].discount +
            '%</strong></a></span>'
      }
      html = html + '</td></tr>'
    }

    $('.table-dates-times').html(html)
  },

  //Set prices
  setPrice: function (prices, product, discount, currency) {
    var p_short = 0,
      p_middle = 0,
      p_long = 0

    prices.forEach(function (price) {
      if (price.PID == product) {
        p_short = parseInt(price.p_short)
        p_middle = parseInt(price.p_middle)
        p_long = parseInt(price.p_long)
      }
    })

    //Hide all prices
    $('.h_short').addClass('hide')
    $('.h_middle').addClass('hide')
    $('.h_long').addClass('hide')
    $('.h_middle_only').addClass('hide')

    //Set discount
    $('.date-discount').html('-' + discount + '%')

    //Set prices
    if (p_short === 0 && p_middle === 0 && p_long === 0) {
      $('.table-prices').addClass('hide')
      $('.price-info').removeClass('hide')
    } else {
      $('.table-prices').removeClass('hide')
      $('.price-info').addClass('hide')
    }

    if (p_short === p_middle && p_middle === p_long) {
      $('.p_middle_only').html(p_middle + ' ' + currency)
      $('.p_middle_only_discount').html(roundNumber((p_middle / 100) * (100 - discount)) + ' ' + currency)
      $('.h_middle_only').removeClass('hide')
    } else {
      if (p_short !== 0) {
        $('.p_short').html(p_short + ' ' + currency)
        $('.p_short_discount').html(roundNumber((p_short / 100) * (100 - discount)) + ' ' + currency)
        $('.h_short').removeClass('hide')
      }
      if (p_middle !== 0) {
        $('.p_middle').html(p_middle + ' ' + currency)
        $('.p_middle_discount').html(roundNumber((p_middle / 100) * (100 - discount)) + ' ' + currency)
        $('.h_middle').removeClass('hide')
      }
      if (p_long !== 0) {
        $('.p_long').html(p_long + ' ' + currency)
        $('.p_long_discount').html(roundNumber((p_long / 100) * (100 - discount)) + ' ' + currency)
        $('.h_long').removeClass('hide')
      }
    }
  },

  getSalonNames: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/salons/getNames',
      success: function (data) {
        const json = $.parseJsonString(data)

        $('#form-search-salon input').typeahead({
          source: json,
          cancelButton: false,
          minLength: 1,
        })
      },
    })
  },

  getLink: function (data) {
    void $.ajax({
      type: 'POST',
      data: data,
      url: '/api/salon/getLink',
      success: function (data) {
        const json = $.parseJsonString(data)

        if (json) {
          location.href = json.link
        }
      },
    })
  },

  getSalonsSearchSalons: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/salons/salonSearchSalons',
      success: function (data) {
        const json = $.parseJsonString(data)

        if (json) {
          $('.salon-search-popular').html(json.popular)
          $('.salon-search-booked').html(json.booked)
          $('.salon-search-newest').html(json.newest)
        }
      },
    })
  },
}

/**
 * Salon
 **/
var salons = {
  jQuerycontainer: null,

  setIsotope: function () {
    // in endpoint /salons the container of salon items
    salons.jQuerycontainer = $('.portfolio').imagesLoaded(function () {
      salons.jQuerycontainer.isotope({
        itemSelector: '.item',
      })
    })

    var jQueryoptionSets = $('#options #filters'),
      jQueryoptionLinks = jQueryoptionSets.find('a')

    jQueryoptionLinks.click(function () {
      var jQuerythis = jQuery(this)
      if (jQuerythis.hasClass('selected')) {
        return false
      }
      var jQueryoptionSet = jQuerythis.parents('#filters')
      jQueryoptionSet.find('.selected').removeClass('selected')
      jQuerythis.addClass('selected')

      var selector = $(this).attr('data-option-value')
      salons.jQuerycontainer.isotope({ filter: selector })

      return false
    })
  },
}

/**
 * Alerts
 **/
var alerts = {
  getAlertSuccess: function (msg) {
    return (
      '<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' +
      msg +
      '</div>'
    )
  },
  getAlertDanger: function (msg) {
    return (
      '<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' +
      msg +
      '</div>'
    )
  },
}

/**
 * Helper
 **/
var formatLocalDate = function (date) {
  if (date === null) {
    return ''
  }
  return zeroPad(date.getDate()) + '.' + zeroPad(date.getMonth() + 1) + '.' + date.getFullYear()
}
var zeroPad = function (number) {
  var str = number.toString()
  return str.length > 1 ? str : '0' + str
}
function roundNumber(num, dec) {
  var d = 1
  for (var i = 0; i < dec; i++) {
    d += '0'
  }
  return Math.round(num * d) / d
}
function getURLParameter(sParam) {
  var hash
  var decodedUrl = decodeURIComponent(window.location.href)
  var hashes = decodedUrl.slice(decodedUrl.indexOf('?') + 1).split('&')
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=')
    if (hash[0] == sParam) {
      return hash[1]
    }
  }
}
