// Fonts
import '~js/css/fontAwesome.ts'
import '~public/css/src/fonts/open-sans.css'
import '~public/css/src/fonts/lato.css'

// Styles
import '~public/css/src/default/style.css'

// Scripts
import '~src/globals/jquery.ts' // sets jquery globally
import 'jquery.cycle2/src/jquery.cycle2.min.js'
import 'historyjs/scripts/bundled-uncompressed/html5/jquery.history.js'
import 'waypoints/lib/jquery.waypoints.min.js'
import 'jquery-typeahead/dist/jquery.typeahead.min.js'

// FIXME remove isotope
// @ts-ignore
import Isotope from 'isotope-layout'
// @ts-ignore
import jQueryBridget from 'jquery-bridget'
// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $)

import 'bootstrap/dist/js/bootstrap.js'
import '~src/globals/moment.ts' // sets moment globally
import '~public/js/src/default/bootstrap-datepicker.js'
import 'bootstrap-select/dist/js/bootstrap-select.js'
import 'imagesloaded/imagesloaded.pkgd.js'

// @ts-ignore

import '~src/globals/toastr.ts' // sets toastr globally

import '~public/js/src/default/two-factor-authentication.js'
import '~public/js/src/default/custom.js'
